import React from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import arrowImg from "../../assets/images/dropdown-arrow.svg";
import "./styles.scss";

const Dropdown = (props) => {
  const {
    children,
    className,
    containerClass,
    borderClass,
    options,
    onClick,
    value,
    dropdownOpen,
    onDropdownClick,
    name
  } = props;

  return (
    <div
      className={
        containerClass
          ? `${containerClass} dropdown-container`
          : "dropdown-container"
      }
    >
      <div className='w-100'>
        <h6 className="dropdown-description">
          {props.title ? props.title : ""}
        </h6>
        <h6 className="dropdown-description">
          {props.description ? props.description : ""}
        </h6>
      </div>
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={onDropdownClick}
        className={`
          ${className
            ? `${className} dropdown-inner-container d-flex justify-content-end  height72`
            : `dropdown-inner-container d-flex justify-content-end  height72`}`
        }
      >
        <DropdownToggle
          caret
          className={
            borderClass
              ? `${borderClass} border-5 py-3 custom__btn d-flex justify-content-between`
              : "border-5 py-3 custom__btn d-flex justify-content-between"
          }
        >
          <div style={{ height: "72px" }} className="d-flex align-items-center">
            <div className="dropdown-icon d-flex align-items-center ">
              {props.image}
            </div>
            <div className="dropdown-text">{value || props.text}</div>
            <img alt="" className="dropdown-arrow" src={arrowImg} />
          </div>
        </DropdownToggle>
        <DropdownMenu
          onClick={onClick}
          children={[]}
          name={name}
        >
          {children}
          {options}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default Dropdown;
