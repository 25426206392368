import React, { useEffect, useState, useRef } from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import Layout from "../../components/Layout";
import { Row, Col, DropdownItem } from "reactstrap";
import Card from "../../components/Card/Card";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import StairsIcon from "@mui/icons-material/Stairs";
import { connect } from "react-redux";
import { graphql } from 'gatsby';
import {
  getProjects,
  getProjectDetails,
} from "../../reducers/Projects/actions";
import checkIcon from '../../assets/images/project-check-icon.svg';
import howitWorksImg1 from '../../assets/images/howitworks1.svg';
import howitWorksImg2 from '../../assets/images/howitworks2.svg';
import howitWorksImg3 from '../../assets/images/howitworks3.svg';
import howitWorksImg4 from '../../assets/images/howitworks4.svg';
import Pagination from "../../components/Common/Pagination";
import ApartmentIcon from '@mui/icons-material/Apartment';
import { scrollToElement } from '../../utils/util';
import { urlDescription } from '../../utils/pageDescription'
import { returnArchitectName } from '../../utils/helpers'
import "./styles.scss";

const ProjectsPage = ({
  projects,
  page,
  getProjects,
  getProjectDetails,
  totalPages,
  data
}) => {
  const [dropdownState, setDropdownState] = useState({});
  const [isDropOpen, setIsDropOpen] = useState({
    squares: false,
    floors: false,
    basement: false,
  });

  const nodes = data.allStrapiProjects.edges
  
  const titleRef = useRef(null)
  
  useEffect(() => {
    getProjects({
      page,
      floor: dropdownState.floors ? Number(dropdownState.floors) : null,
      minArea: dropdownState.minArea ? Number(dropdownState.minArea) : null,
      maxArea: dropdownState.maxArea ? Number(dropdownState.maxArea) : null,
      basement: dropdownState.basement
    });
  }, [getProjects, page, dropdownState]);

  const updateDropdown = (key) => {
    setIsDropOpen((isDropOpen) => {
      return { ...isDropOpen, [key]: !isDropOpen[key] };
    });
  };

  const handleDropValue = (e) => {
    const key = e.currentTarget.getAttribute("name");
    const val = e.target.value;
    if (key === 'squares') {
      if (val === 'Всички') {
        setDropdownState({
          ...dropdownState,
          [key]: val,
          ['minArea']: null,
          ['maxArea']: null
        });
        return
      }
      let value = val.split('-')
      setDropdownState({
        ...dropdownState,
        minArea: value[0],
        maxArea: value[1] !== '' ? value[1] : null,
      });
      return
    }
    setDropdownState({
      ...dropdownState,
      [key]: val,
    });
  };

  const handlePageClick = (data) => {
    getProjects({
      page: data.selected + 1,
      floor: dropdownState.floors ? Number(dropdownState.floors) : null,
      minArea: dropdownState.minArea ? Number(dropdownState.minArea) : null,
      maxArea: dropdownState.maxArea ? Number(dropdownState.maxArea) : null,
      basement: dropdownState.basement
    });
  }

  const collectionInfo = [
    {
      title: 'Гъвкавост',
      desc: 'Проектите лесно се адаптират за изпълнение с избраните от Вас материали и технология на изграждане.'
    },
    {
      title: 'Професионализъм',
      desc: 'Избраните проекти съчетават националната ни традиция с модерната визия и удобствата на един съвременен дом.'
    },
    {
      title: 'Избор',
      desc: 'Тук са събрани единствено авторски проекти. Фокусът е върху индивидуалността, вниманието към детайла и съпреживяването на творческата идея.'
    },
    {
      title: 'Подкрепа',
      desc: 'По време на проектирането и изпълнението на Ваше разположение е опитът на доказани в своята област професионалисти, които ще Ви напътстват и съветват не само за най-важните неща, а и за скритите детайли.'
    },
    {
      title: 'Ваучери за материал',
      desc: 'Имате възможност да се възползвате от нашите актуални промоционални кампании.'
    },
  ];

  const howItWorks = [
    {
      image: howitWorksImg1,
      title: 'Индивидуален дизайн',
      desc: 'Селектирали сме проекти на изявени български архитекти. Ще добавяме нови предложения в нашия своеобразен каталог на архитектурни произведения в индивидуалното жилищно строителство.'
    },
    {
      image: howitWorksImg2,
      title: 'Лична ангажираност',
      desc: 'Представените автори са готови да проектират и Вашия дом. Могат да адаптират показаните решения спрямо Вашите нужди или да създадат за Вас напълно нов архитектурен проект.'
    },
    {
      image: howitWorksImg3,
      title: 'Утвърдени продукти',
      desc: 'Нашите продукти и решения могат да намерят място във всеки проект. Знаем, че търсите качество и сигурност за Вашето ново жилище. Запознайте се с нашите предложения:',
      hyperlink_text: 'линк към решения за стени.',
      hyperlink: '/walls-solutions-ytong'

    },
    {
      image: howitWorksImg4,
      title: 'Експертна подкрепа',
      desc: 'Специалисти на КСЕЛА България са на Ваше разположение за безплатна експертна консултация по  търговски, логистични или продажбите или  технически въпроси. Запазете час за среща на:',
      hyperlink_text: 'www.expert.ytong.bg.',
      hyperlink: 'https://expert.ytong.bg/'
    },
  ];

  const rows = collectionInfo.reduce(function (rows, key, index) {
    return (index % 2 === 0 ? rows.push([key])
      : rows[rows.length - 1].push(key)) && rows;
  }, []);

  const returnRZPValue = (dropdownState) => {
    if (dropdownState.minArea === '0' && dropdownState.maxArea === '100') {
      return 'от 0 до 100 кв. м'
    }
    else if (dropdownState.minArea === '100' && dropdownState.maxArea === '200') {
      return 'от 100 до 200 кв. м'
    }
    else if (dropdownState.minArea === '200' && dropdownState.maxArea === null) {
      return 'над 200 кв. м'
    }
    else return 'Квадратура'
  }

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="projects-page-wrapper">
        <div className="projects-page-container">
          <div className="projects-page-title">
            <h1 ref={titleRef} className="h2 font-weight-bold">Избор на проект</h1>
          </div>
          <div className="w-75-i mx-auto">
            <Row className='justify-content-center'>
              <Col sm={12} md={5} lg={4} >
                <Dropdown
                  name="squares"
                  text="РЗП"
                  value={returnRZPValue(dropdownState)}
                  className="width250"
                  containerClass="align-items-center"
                  image={<SquareFootIcon />}
                  dropdownOpen={isDropOpen.squares}
                  onDropdownClick={() => updateDropdown("squares")}
                  onClick={handleDropValue}
                  options={
                    <>
                      <DropdownItem value="0-100" className="cursor-pointer">
                        от 0 до 100 кв. м
                      </DropdownItem>
                      <DropdownItem value="100-200" className="cursor-pointer">
                        от 100 до 200 кв. м
                      </DropdownItem>
                      <DropdownItem value="200-" className="cursor-pointer">
                        над 200 кв. м
                      </DropdownItem>
                      <DropdownItem value='Всички' className="cursor-pointer">
                        Всички
                      </DropdownItem>
                    </>
                  }
                />
              </Col>
              <Col sm={12} md={5} lg={4}>
                <Dropdown
                  text="Етажи"
                  name="floors"
                  value={dropdownState.floors}
                  containerClass="align-items-center"
                  image={<ApartmentIcon />}
                  dropdownOpen={isDropOpen.floors}
                  onDropdownClick={() => {
                    updateDropdown("floors");
                  }}
                  onClick={handleDropValue}
                  className="width250"
                  options={
                    <>
                      <DropdownItem value={1} className="cursor-pointer">
                        1
                      </DropdownItem>
                      <DropdownItem value={2} className="cursor-pointer">
                        2
                      </DropdownItem>
                      <DropdownItem value={3} className="cursor-pointer">
                        3
                      </DropdownItem>
                      <DropdownItem value='Всички' className="cursor-pointer">
                        Всички
                      </DropdownItem>
                    </>
                  }
                />
              </Col>
              <Col sm={12} md={5} lg={4}>
                <Dropdown
                  text="Сутерен"
                  name="basement"
                  value={dropdownState.basement}
                  containerClass="align-items-center"
                  image={<StairsIcon />}
                  dropdownOpen={isDropOpen.basement}
                  onDropdownClick={() => {
                    updateDropdown("basement");
                  }}
                  onClick={handleDropValue}
                  className="width250"
                  options={
                    <>
                      <DropdownItem value="Да" className="cursor-pointer">
                        Да
                      </DropdownItem>
                      <DropdownItem value="Не" className="cursor-pointer">
                        Не
                      </DropdownItem>
                      <DropdownItem value='Всички' className="cursor-pointer">
                        Всички
                      </DropdownItem>
                    </>
                  }
                />
              </Col>
            </Row>
          </div>
          <div className="mt-5">
            <Row className="projects-row">
              {
                projects && projects.length > 0
                  ? projects.map((p, i) => {
                    const gatsbyImage = nodes.find(value => value.node.id === p.id)
                    return (
                      <Col
                        key={i}
                        sm={12}
                        md={6}
                        className="my-4 px-4"
                      >
                        <Card
                          cardClass="projects-horizontal-card"
                          imageClass="new-horizontal-card-img"
                          cardBodyClass="homepage-card-body align-items-end"
                          url={`/project-details/${gatsbyImage ? gatsbyImage.node.id : p.id}`}
                          btnClass="horizontal-card-btn-color"
                          title={p.title}
                          subtitle={returnArchitectName(p.author_name, p.architect_id)}
                          image={gatsbyImage ? gatsbyImage?.node.image.localFile.childImageSharp : `${process.env.GATSBY_XELLA_BACKEND}${p.image.url} `}
                          isGatsbyImage={gatsbyImage ? true : false}
                          onClick={() => {}}
                        />
                      </Col>
                    );
                  })
                  : null
              }
            </Row>
            <Row className="w-100 justify-content-center mt-5">
              <div>
                {
                  totalPages && totalPages > 1
                    ? <Pagination
                      handlePageClick={(data) => {
                        handlePageClick(data);
                        scrollToElement(titleRef)
                      }}
                      pageCount={totalPages}
                      currentPage={page}
                    />
                    : null
                }
              </div>
            </Row>
          </div>
        </div>
      </div>
      <hr />
      <div className='projects-info-tab my-5 p-5'>
        <Row className='projects-info-tab__row'>
          <Col>
            <h2 className='h3 projects-info-tab__heading'>
              Селекция с добавена стойност
            </h2>
          </Col>
        </Row>
        {
          rows.map((row, i) => (
            <Row key={i} className='projects-info-tab__row'>
              {row.map((col, index) => (
                <div
                  key={index}
                  className="projects-info-tab__col-wrapper"
                >
                  <Col
                    lg={7}
                    className='projects-info-tab__image-col'
                  >
                    <img alt="" className='projects-info-tab__image' src={checkIcon} />
                  </Col>
                  <Col lg={5}>
                    <h6 className='projects-info-tab__title'>{col.title}</h6>
                    <p>{col.desc}</p>
                  </Col>
                </div>
              ))}
            </Row>
          ))
        }
      </div>
      <hr />
      <div className='projects-info-tab my-5 p-5'>
        <Row className='projects-info-tab__row'>
          <Col>
            <h2 className='h3 projects-info-tab__heading'>
              Защо да ни се доверите?
            </h2>
          </Col>
        </Row>
        <Row className='projects-info-tab__row'>
          {howItWorks.map((item, i) => (
            <Col sm={12} md={6} lg={3} key={i} >
              <img
                alt=""
                className="projects-info-tab__image"
                src={item.image}
              />
              <h5 className='projects-info-tab__title'>
                {item.title}
              </h5>
              <p>{item.desc} {item.hyperlink ? <a className='blue-link' href={item.hyperlink}>{item.hyperlink_text}</a> : null}</p>
            </Col>
          ))}
        </Row>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    projects: state.projects.data,
    page: state.projects.current_page,
    totalPages: state.projects.total_pages
  };
};

const mapDispatchToProps = {
  getProjects,
  getProjectDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);

export const query = graphql`
query {
  allStrapiProjects {
    edges {
      node {
        id: strapiId
        title
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        other_images {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
} `
